<template>
  <VCard
    height="340px"
    class="template-card pa-2"
    @click="$emit('click')"
  >
    <VCardText 
      class="white--text"
      style="position: absolute; bottom: 8px;"
    >
      <h4 class="text-caption font-weight-bold text-uppercase text-truncate pr-3">
        // {{ item.program_type.title.capitalize() }}
      </h4>
      <h3 
        class="font-weight-medium text-truncate pr-3 py-1"
        style="font-size: 18px;"
      >
        {{ item.title.capitalize() }}
      </h3>
      <p 
        v-html="item.content" 
        class="content formatted text-caption my-3"
      />
      <VChip 
        v-for="(content, index) in attachedContent"
        color="#FAF9FF"
        class="font-weight-medium mr-2 mt-2"
        :key="index"
        :style="{
          fontSize: '11px',
          opacity: content.count ? 1 : 0.5,
        }"
      >
        {{ content.count }} {{ content.title }}
      </VChip>
    </VCardText>
  </VCard>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    attachedContent() {
      return [
        {
          title: this.featureName("Pipelines"),
          count: this.item.pipelines_count,
        },
        {
          title: this.featureName("Lessons"),
          count: this.item.lessons_count,
        },
        {
          title: this.featureName("Deliverables"),
          count: this.item.deliverables_count,
        },
        {
          title: this.featureName("Contents"),
          count: this.item.primary_contents_count,
        },
        {
          title: this.featureName("FAQs"),
          count: this.item.faqs_count,
        },
      ];
    },
  },
}
</script>
<style lang="scss">
.template-card {
  background: linear-gradient(138.06deg, #0A5894 0.77%, #2296F0 95.96%); 
  box-shadow: 0px 0px 15px 0px #0000000D !important;
  .content {
    overflow: hidden auto;
    max-height: 148px;
    p {
      margin-bottom: 0px !important;
    }
  }
}
</style>