<template>
  <VRow>
    <VCol 
      cols="12"
      class="pb-0"
    >
      <h2 
        class="d-flex"
        style="font-size: 32px;"
      >
        {{ featureName(parentModelType, 'singularize') }} Templates
        <span v-if="programType">: {{ programType.title }}</span>
      </h2>
    </VCol>
    <VCol cols="12">
      <DataViewUI
        hide-headers
        ref="dataViewUI"
        default-view-mode="grid"
        class="templates-index"
        :data="data"
        :row-classes="false"
        :grid-padding="false"
        :is-loading="activity.isLoading"
        :rows-per-page="[12, 24, 48, 96]"
        :search-label="`Search ${featureName(parentModelType, 'singularize')} templates...`"
        @search="onSearch"
        @paginate="onGetMore"
      >
        <template #title>
          <span 
            class="text-body-1"
            style="width: 652px;"
          >
            To simplify your process, we have created {{ featureName(parentModelType, 'singularize') }} Templates based on the most common programs and best practices. You can preview the contents here before importing.
          </span>
        </template>
        <template #card="{item}">
          <Card 
            :item="item"
            @click="onSetViewDialogModel(item)"
          />
        </template>
      </DataViewUI>
      <Dialog
        max-width="612px"
        close-icon-color="white !important"
        custom-class="alt-dialog-title view-dialog"
        :is-dialog-open="dialogs.view"
        @toggle:dialog="onToggleViewDialog"
      >
        <template
          v-if="currentModel" 
          #title
        >
          <div style="width: calc(100% - 40px);">
            <h4 class="text-caption font-weight-bold text-uppercase text-truncate">
              {{ currentModel.program_type.title.capitalize() }}
            </h4>
            <h3 class="text-h6 font-weight-bold text-truncate">
              {{ currentModel.title.capitalize() }}
            </h3>
            <p 
              v-html="currentModel.content" 
              class="content formatted text-caption my-2"
            />
            <VBtn
              height="44px"
              class="rounded-lg mt-1"
              style="color: #0A5894;"
              @click="$router.push({
                name: 'program.index',
                params: {
                  template: currentModel,
                },
              })"
            >
              <span class="text-caption font-weight-bold">Use This Template</span>
            </VBtn>
          </div>
        </template>
        <ViewComponent :id="currentModel ? currentModel.id : null" />
      </Dialog>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
import Card from "./Card";
import ViewComponent from "./View";

export default {
  name: "TemplateIndex",
  mixins: [SearchMixin],
  components: {
    Card,
    ViewComponent,
  },
  data() {
    return {
      modelType: "template",
      parentModelType: "Program",
      programTypes: [],
    };
  },
  watch: {
    '$route.params.typeSlug': {
      handler: function() {
        this.$refs.dataViewUI.searchString = "";
        this.onFilterData();
      },
    },
  },
  computed: {
    programType() {
      if(this.$route.params.typeSlug && this.programTypes.length) {
        return this.programTypes.find((type) => type.slug === this.$route.params.typeSlug);
      }
      return null;
    },
  },
  created() {
    this.getTemplatesTypes();
  },
  methods: {
    ...mapActions({
      doGetTemplates: "template/getAll",
      doGetTemplatesTypes: "template/getTypes",
    }),
    getTemplatesTypes() {
      this.doGetTemplatesTypes({
        type: "program",
        perPage: 1000,
      }).then((result) => {
        this.programTypes = result.data;
        this.$bus.$emit("templatesMenuItems", result.data.map((type) => {
          type.isImage = true;
          type.enabled = true;
          type.icon = require("@/images/template-icon.png");
          type.route = {
            name: "template.index",
            params: {
              team: this.$team.slug,
              typeSlug: type.slug,
            },
          };
          return type;
        }));
      });
    },
    onGetAll(params) {
      params.perPage = 12;
      params.type = "program";
      if(this.$route.params.typeSlug) {
        params.filter.filter = {
          programTypeSlug: this.$route.params.typeSlug,
        };
      }
      return this.doGetTemplates(params);
    },
  },
};
</script>

<style lang="scss">
.templates-index {
  background-color: transparent !important;
  .v-card__title {
    word-break: break-word;
    border-bottom: none !important;
    padding-left: 0px !important;
  }
  .search-field {
    width: 280px;
    height: 36px;
    flex: none;

    .v-input__prepend-outer {
      display: none;
    }
    .v-input__append-outer {
      margin: 0px 14px !important;
    }
    .v-input__slot {
      min-height: 36px !important;
    }
    .v-label {
      font-weight: 400 !important;
      font-size: 15px !important;
    }
  }
}
.view-dialog {
  border-radius: 10px;
  .v-card {
    border-radius: 12px;
  }
  .dialog-card-title {
    word-break: break-word;
    border-radius: 8px 8px 0 0 !important;
    padding: 20px 16px 20px 20px !important;
    height: auto !important;
    flex: none !important;
    background: linear-gradient(138.06deg, #0A5894 0.77%, #2296F0 95.96%);
    color: white;
  }
}
</style>
