<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="!modelHasId"
  >
    <VRow class="mx-n1">
      <VCol cols="12">
        <h1 class="text-body-1 font-weight-bold">
          Template Contents
        </h1>
        <VCard 
          v-for="(feature, index) in features"
          color="#FAF9FF"
          class="rounded my-6 pa-1"
          :key="index"
        >
          <VCardTitle>
            <img 
              height="24px"
              width="24px"
              :src="feature.icon"
            >
            <h4 class="text-subtitle-2 ml-4">
              {{ feature.title }}
            </h4>
          </VCardTitle>
          <VCardText class="py-0">
            <ul>
              <li 
                v-for="(item, key) in model[feature.name]" 
                class="text-caption"
                style="color: #666666;"
                :key="key"
              >
                {{ item.title }}
              </li>
            </ul>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";

export default {
  mixins: [ViewMixin],
  computed: {
    features() {
      return [
        {
          name: "pipelines",
          title: this.featureName("Pipelines"),
          icon: require("../../../images/application-black-icon@3x.png"),
        },
        {
          name: "lessons",
          title: this.featureName("Lessons"),
          icon: require("../../../images/lessons-black-icon.png"),
        },
        {
          name: "primary_contents",
          title: this.featureName("Content"),
          icon: require("../../../images/content-black-icon.png"),
        },
        {
          name: "deliverables",
          title: this.featureName("Deliverables"),
          icon: require("../../../images/forms-black-icon.png"),
        },
        {
          name: "faqs",
          title: this.featureName("FAQs"),
          icon: require("../../../images/faq-black-icon.png"),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      doGetTemplate: "template/getOne",
    }),
    onGet(id) {
      return this.doGetTemplate({
        id,
        type: "program",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card__title {
  height: auto !important;
}
</style>